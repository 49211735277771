<template>
  <view-item-setting>
    <template #operation>
      <en-button type="primary" @click="operation.add.click">新建充值套餐</en-button>
    </template>
    <tabs-maintain
      :ajax="{ action: 'GET /enospray/common/lookup/:lookupType', params: (params) => (params.paths = ['SMTUCOS']) }"
      :props="{ value: 'code', label: 'message' }"
      v-model="tabs.statusCode"
      :method="table.get"
    >
    </tabs-maintain>
    <flex-box>
      <template #default="{ height }">
        <table-dynamic
          :height="height"
          code="STMSCOORFD"
          :data="table.data"
          :loading="table.loading"
          :paging="table.paging"
          :method="table.get"
          pagination
          :config="table.config"
        >
          <template #SERIAL_NO="{ row }: { row: EnosprayShortMessageDefinitions['ShortMessageTopUpComboDto'] }">
            <en-button type="primary" link @click="table.serialNo.click(row)">{{ row.serialNo }}</en-button>
          </template>
        </table-dynamic>
      </template>
    </flex-box>
  </view-item-setting>
  <en-drawer v-model="detail.visible" :title="!detail.form.data.id ? '新建充值套餐' : '编辑套餐'">
    <en-form :model="detail.form.data" :rules="detail.form.rules" :ref="setRef('detailForm')">
      <en-form-item label="充值门店" prop="tenantId">
        <select-maintain
          v-model="detail.form.data.tenantId"
          :ajax="{ action: 'GET /enospray/common/tenant', params: (params, value) => (params.payload = { shortName: value }) }"
          :props="{ label: 'shortName', value: 'id' }"
          remote
          :disabled="detail.form.formDataDisabled"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="充值金额" prop="amount">
        <en-input-number
          :controls="false"
          v-model="detail.form.data.amount"
          :min="0"
          :precision="2"
          :disabled="detail.form.formDataDisabled"
          class="w-full"
        ></en-input-number>
      </en-form-item>

      <en-form-item label="充值数量" prop="count">
        <en-input-number
          :controls="false"
          v-model="detail.form.data.count"
          :min="0"
          :precision="0"
          :disabled="detail.form.formDataDisabled"
          class="w-full"
        ></en-input-number>
      </en-form-item>
      <en-form-item label="发票类型" prop="invoiceType">
        <select-maintain
          v-model="detail.form.data.invoiceType"
          :ajax="{ action: 'GET /enocloud/common/hint/:hintTypeStr', params: (params) => (params.paths = ['INVCTP']) }"
          :props="{ label: 'name', value: 'name' }"
          class="w-full"
          :disabled="detail.form.formDataDisabled"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="发票抬头" prop="invoiceTitle">
        <en-input v-model="detail.form.data.invoiceTitle" :disabled="detail.form.formDataDisabled"></en-input>
      </en-form-item>
      <en-form-item label="发票内容" prop="invoiceContents">
        <select-maintain
          v-model="detail.form.data.invoiceContents"
          :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['IVCCTS']) }"
          :props="{ label: 'message', value: '' }"
          value-key="code"
          class="w-full"
          :disabled="detail.form.formDataDisabled"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="联系人电话" prop="invoiceContactPhone">
        <en-input v-model="detail.form.data.invoiceContactPhone" :disabled="detail.form.formDataDisabled"></en-input>
      </en-form-item>
      <en-form-item label="联系人地址" prop="invoiceContactAddress">
        <en-input v-model="detail.form.data.invoiceContactAddress" :disabled="detail.form.formDataDisabled"></en-input>
      </en-form-item>
      <en-form-item label="纳税人识别号" prop="taxpayerIdentificationNumber">
        <en-input v-model="detail.form.data.taxpayerIdentificationNumber" :disabled="detail.form.formDataDisabled"></en-input>
      </en-form-item>
      <en-form-item label="银行基本账户" prop="bankAccount">
        <en-input v-model="detail.form.data.bankAccount" :disabled="detail.form.formDataDisabled"></en-input>
      </en-form-item>
      <en-form-item label="开户行" prop="bankName">
        <en-input v-model="detail.form.data.bankName" :disabled="detail.form.formDataDisabled"></en-input>
      </en-form-item>
    </en-form>
    <template #footer>
      <en-button @click="detail.footer.cancel.click">取消</en-button>
      <button-ajax :method="detail.footer.confirm.click" v-if="!detail.form.data.id"> 确定 </button-ajax>
    </template>
  </en-drawer>
</template>

<script lang="ts">
export default factory({
  config: {
    children: {
      operation: {
        add: {
          click() {
            this.detail.form.init()
            this.detail.visible = true
          }
        }
      },
      tabs: {
        statusCode: ''
      },
      form: {
        is: 'form',
        data: {
          serialNo: '',
          startDate: '',
          endDate: '',
          tenantName: ''
        },
        init() {
          this.form.data = {
            serialNo: '',
            startDate: '',
            endDate: '',
            tenantName: ''
          }
        }
      },

      table: {
        ajax: {
          get: {
            action: 'GET /enospray/short/message/combo/order',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = { ...this.tabs, ...this.form.data }
            }
          },
          audit: {
            action: 'POST /enocloud/short/message/combo/:comboId/audit'
          },
          reverse: {
            action: 'POST /enocloud/short/message/combo/:comboId/reverse/audit'
          },
          discard: {
            action: 'POST /enocloud/short/message/combo/:comboId/discard'
          }
        },
        children: {
          operation: {
            putaway: {
              click(row: EnosprayShortMessageDefinitions['ShortMessageTopUpComboDto']) {
       

                this.table.audit({ paths: [row.id] }).then((res) => {
                  this.table.get()
                })
              }
            },
            remove: {
              click(row: EnosprayShortMessageDefinitions['ShortMessageTopUpComboDto']) {
                this.table.reverse({ paths: [row.id] }).then((res) => {
                  this.table.get()
                })
              }
            },
            discard: {
              click(row: EnosprayShortMessageDefinitions['ShortMessageTopUpComboDto']) {
                this.table.discard({ paths: [row.id] }).then((res) => {
                  this.table.get()
                })
              }
            }
          }
        },
        serialNo: {
          async click(row: EnosprayShortMessageDefinitions['ShortMessageTopUpComboDto']) {
            this.detail.form.init()
            this.detail.form.data.id = row.id
            await this.detail.form.get()
            this.detail.visible = true
          }
        },
        config: {
          SERIAL_NO: { header: { filter: { type: 'text', field: 'serialNo' } } },
          NAME: { header: { filter: { type: 'text', field: 'name' } } },
          TENANT_NAME: { header: { filter: { type: 'text', field: 'tenantName' } } },
          PREPARED_DATETIME: {
            header: {
              filter: {
                type: 'date',
                field: ['startDate', 'endDate'],
                props: { type: 'daterange' }
              }
            }
          }
        }
      },
      detail: {
        visible: false,
        children: {
          form: {
            is: 'form',
            data: {
              id: '',
              serialNo: '',
              name: '',
              count: 1,
              price: 0,
              status: {
                code: ''
              }
            },
            ajax: {
              get: {
                action: 'GET /enospray/short/message/combo/order/:orderId',
                data: 'object',
                init: true,
                loading: true,
                pagination: true,
                params(params) {
                  params.paths = [this.detail.form.data.id]
                }
              },
              submit: {
                action: 'POST /enospray/short/message/combo/order',

                loading: true,
                pagination: true,
                params(params) {
                  params.payload = this.detail.form.data
                }
              }
            },
            computed: {
              formDataDisabled() {
                let code = this.detail.form.data.status.code
                return ['A', 'P'].includes(code)
              }
            },
            rules: {
              tenantId: [{ required: true, message: '请选择充值门店' }],
              amount: [{ required: true, message: '请输入充值金额' }],
              count: [{ required: true, message: '请输入充值数量' }]
            }
          },
          footer: {
            cancel: {
              click() {
                this.detail.visible = false
              }
            },
            confirm: {
              async click() {
                await this.detail.form[this.detail.form.data.id ? 'update' : 'submit']()
                return this.table.get().then(() => (this.detail.visible = false))
              }
            }
          }
        }
      }
    }
  },

  mounted() {
    this.table.get()
  }
})
</script>
